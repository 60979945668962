import { useState, useEffect, useContext, useRef } from 'react';
import './flashcard.scss';
import { wordContext } from '../../contexts/wordContext';
import API from '../../services/axiosConfig';

const moment = require('moment-timezone');

const Flash7 = () => {
    const [index, setIndex] = useState(0);
    const [showButton, setShowButton] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const yesCountRef = useRef(0);
    const noCountRef = useRef(0);

    const {yesCount , setYesCount } = useContext(wordContext)
    const { testIndex, setTestIndex } = useContext(wordContext);
    const { contents, setContents } = useContext(wordContext);
    const { deckData, setDeckdata } = useContext(wordContext);
    const { isLoggedIn, setIsLoggedIn } = useContext(wordContext);
    const { testFinished, setTestFinished } = useContext(wordContext);

    const getTestIndex = async () => {
        try {
            const url = `${process.env.REACT_APP_API_URL}:3006/testIndex`;
            const response = await API.get(url);
            const data = response.data[0].idx;
            setTestIndex(data + 1);
        } catch (error) {
            console.error("error fetching testIndex: ", error);
        }
    };

    useEffect(() => {
        getTestIndex();
    }, [isLoggedIn]);

    const sendData = (correctState) => {
        let wordData = {
            wordId: contents[index].word_id,
            isCorrect: correctState,
            test_form: "test",
            testid: testIndex,
            word_date: moment().tz('Asia/Seoul').format(),
            word_deck: deckData
        };

        API.post(`${process.env.REACT_APP_API_URL}:3006/send_word_result/`, wordData)
            .then(response => {
                console.log(response.data);
            })
            .catch(error => {
                console.error("there is an error", error);
            });
    };

    const sendResult = () => {
        const totalCount = contents.length;

        const resultData = {
            test_id: testIndex,
            yes_cards: yesCountRef.current,
            total_cards: totalCount,
            test_date: moment().tz('Asia/Seoul').format('YYYY-MM-DD HH:mm:ss'),
            test_deck: deckData,
        };

        console.log('Sending resultData:', resultData);

        API.post(`${process.env.REACT_APP_API_URL}:3006/test_result_table`, resultData)
            .then(response => {
                console.log(response.data);
            })
            .catch(error => {
                console.log("there is an error", error);
            });
    };

    const handleClick = () => {
        console.log("box-clicked");

        if (!isActive) {
            setIsActive(true);
            console.log("isActive: ", isActive);
        }
    };

    const moveIndex = () => {
        if (index < contents.length - 1) {
            setIndex(index + 1);
            setIsActive(false); // Reset the active state of the card
        } else {
            setTestFinished(true);
            setIsActive(false); // Reset the active state of the card
            setIndex(0);
            noCountRef.current = 0;
            setTestIndex(testIndex + 1);

            sendResult();
        }
    };

    // In the yesButton and noButton functions
    const yesButton = () => {
        yesCountRef.current += 1;
        setYesCount(yesCountRef.current); // Update the context state
        sendData(true);

        moveIndex();
        console.log("yesCnt: ", yesCountRef.current);
    };


    const noButton = () => {
        noCountRef.current += 1;
        sendData(false);
        if (index === contents.length - 1) {
            sendResult();
        }
        moveIndex();
    };

    var frontContent = contents[index] ? contents[index].word_front : "";
    var backContent = contents[index]
        ? contents[index].word_front + "<br><br> <hr> <br>" + contents[index].word_back
        : "";

    return (
        <div className="container2" onClick={handleClick}>
            <div className="quiz-container">
                <div className='card-container'>
                    <p className='card-index'>
                        {index + 1}/{contents.length}
                    </p>
                </div>
                <div className="quiz-header">
                    <div className="word_form">
                        {contents.length > 0 && (
                            <div className="word_content">
                                {isActive ? <div dangerouslySetInnerHTML={{ __html: backContent }} /> : frontContent}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className={`button-container ${isActive ? 'flex-display' : 'none-display'}`}>
                <button className="button" onClick={yesButton}> Yes</button>
                <button className="button" onClick={noButton}> No</button>
            </div>
        </div>
    );
};

export default Flash7;
