import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector'; // Import LanguageDetector
import translationEN from '../locales/en/translation.json';
import translationKO from '../locales/ko/translation.json';

// Translation resources
const resources = {
    en: {
        translation: translationEN
    },
    ko: {
        translation: translationKO
    }
};

i18n
    .use(LanguageDetector) // Use LanguageDetector before initReactI18next
    .use(initReactI18next) // Passes i18n down to react-i18next
    .init({
        resources,
        fallbackLng: 'en', // Fallback language if translation key is missing
        debug: true, // For debugging purposes, set to false in production
        interpolation: {
            escapeValue: false // React already handles escaping
        },
        detection: {
            // Order and from where user language should be detected
            order: ['localStorage', 'cookie', 'htmlTag', 'path', 'subdomain'],

            // Keys or params to lookup language from
            lookupLocalStorage: 'i18nextLng',
            lookupCookie: 'i18next',

            // Cache user language on
            caches: ['localStorage'],
            excludeCacheFor: ['cimode'], // Exclude from cache

            // Optional set cookie options, by default this will be a session cookie
            cookieOptions: { path: '/', sameSite: 'strict' }
        }
    });

export default i18n;
