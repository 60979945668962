import React, { useEffect, useState, useContext } from 'react';
import './librarydashboard.css';
import notebook_icon from '../../assets/images/notebook_icon.png';
import API from '../../services/axiosConfig';
import { wordContext } from "../../contexts/wordContext";
import DashDialog from './LibraryDashboardDialog';

const LibraryDashboard = () => {
    const [folders, setFolders] = useState([]);
    const [isAddDeckModalOpen, setIsAddDeckModalOpen] = useState(false);
    const [isDeckModalOpen, setIsDeckModalOpen] = useState(false);
    const [newDeckName, setNewDeckName] = useState('');
    const [selectedDeck, setSelectedDeck] = useState(null);
    const { isLoggedIn } = useContext(wordContext);

    useEffect(() => {
        API.get(`${process.env.REACT_APP_API_URL}:3006/shared_decks`)
            .then(response => {
                const folderData = response.data.map(deck => ({
                    name: deck.deck_name,
                    icon: notebook_icon,
                    route: `deck/${deck.deck_name}`
                }));
                const extraFolderAddDeck = { name: 'add-deck', icon: notebook_icon, route: '#' };

                folderData.push(extraFolderAddDeck);
                setFolders(folderData);
            })
            .catch(error => console.error("There was an error fetching the decks:", error));
    }, [isLoggedIn]);

    const handleAddClick = () => {
        setIsAddDeckModalOpen(true);
    };

    const handleCloseAddDeckModal = () => {
        setIsAddDeckModalOpen(false);
        setNewDeckName('');
    };

    const handleFolderClick = (folder) => {
        if (folder.name === 'add-deck') {
            handleAddClick();
        } else {
            setSelectedDeck(folder.name);
            setIsDeckModalOpen(true);
        }
    };

    const handleCloseDeckModal = () => {
        setIsDeckModalOpen(false);
        setSelectedDeck(null);
    };

    const FolderBox = ({ name, icon }) => {
        const handleClick = () => {
            handleFolderClick({ name, icon });
        };

        return (
            <div className="folder-box" onClick={handleClick}>
                <img src={icon} alt={name} />
                <p>{name}</p>
            </div>
        );
    };

    return (
        <div className="cardDashBoard">
            <div className="dash-header">Hello</div>
            <div className="dash-body">
                {folders.map((folder, index) => (
                    <FolderBox key={index} name={folder.name} icon={folder.icon} />
                ))}
            </div>
            <DashDialog
                isOpen={isDeckModalOpen}
                handleClose={handleCloseDeckModal}
                newDeckName={newDeckName}
                setNewDeckName={setNewDeckName}
                selectedDeck={selectedDeck}
                isAddDeckModalOpen={isAddDeckModalOpen}
            />
        </div>
    );
};

export default LibraryDashboard;
