import axios from 'axios';


const API = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_SERVER_PORT_NUM}`,
    headers: {
        'Content-Type': 'application/json'
    }
});


// Request interceptor to add the Authorization header
API.interceptors.request.use((req) => {
    const token = localStorage.getItem('accessToken');
    if (token) {
        req.headers.Authorization = `Bearer ${token}`;
    }
    return req;
});

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    failedQueue = [];
};

API.interceptors.response.use(
    response => response,
    async error => {
        const originalRequest = error.config;
        console.log('Interceptor caught error:', error);

        if ((error.response.status === 401 || error.response.status === 403) && !originalRequest._retry) {
            console.log('Token expired. Attempting to refresh token.');

            if (isRefreshing) {
                console.log('Already refreshing token. Adding request to queue.');
                return new Promise(function(resolve, reject) {
                    failedQueue.push({ resolve, reject });
                }).then(token => {
                    console.log('Token refreshed from queue. Retrying original request.');
                    originalRequest.headers['Authorization'] = 'Bearer ' + token;
                    return API(originalRequest);
                }).catch(err => {
                    console.log('Error resolving token from queue:', err);
                    return Promise.reject(err);
                });
            }

            originalRequest._retry = true;
            isRefreshing = true;

            return new Promise(function(resolve, reject) {
                console.log('Starting token refresh request.');

                const appUrl = process.env.REACT_APP_API_URL;
                const port =  process.env.REACT_APP_SERVER_PORT_NUM;
                console.log(`url: ${appUrl}:${port}/login/refresh-token`)

                axios.post(`${appUrl}:${port}/login/refresh-token`, {}, { withCredentials: true })
                    .then(({ data }) => {
                        console.log('Token refresh successful.');
                        localStorage.setItem('accessToken', data.accessToken);
                        API.defaults.headers.common['Authorization'] = 'Bearer ' + data.accessToken;
                        originalRequest.headers['Authorization'] = 'Bearer ' + data.accessToken;
                        processQueue(null, data.accessToken);
                        resolve(API(originalRequest));
                    })
                    .catch((err) => {
                        console.log('Token refresh failed:', err);
                        processQueue(err, null);
                        reject(err);
                    })
                    .finally(() => {
                        isRefreshing = false;
                        console.log('Token refresh process complete.');
                    });
            });
        }

        return Promise.reject(error);
    }
);


export default API;