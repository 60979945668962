import Sidebar from './components/Common/Sidebar';
import React, { useContext, useState } from 'react';
import SetupForm from './components/SetupForm/SetupForm';
import Flash7 from './components/Flashcard/flashcard';
import { wordContext } from './contexts/wordContext';
import ResultTable from './pages/RecordTest';
import { Route, Switch, Redirect } from 'react-router-dom';
import Take_test from './pages/take_test';
import Home from './pages/home';
import Review_table from './pages/reviewtest_link';
import StartDrill from './pages/startDrill';
import Statistics from "./pages/statistics";
import RecordDrill from "./pages/RecordDrill";
import Review_Drilltable from "./pages/reviewdrill_link";
import WordResultTable from "./pages/wordResultTable";
import SignInSide from "./pages/login/signin";
import { useLocation } from 'react-router-dom';
import SignUp from "./pages/login/signup";
import Navbar from "./components/Common/navbar";
import Floatingbuttons from "./components/Common/floatingbuttons";
import CreateCard from "./pages/createCards/createCards";
import CreateCardDashBoard from "./pages/createCards/CardDashBoard";
import CardDashBoard from "./pages/createCards/CardDashBoard";
import DeckCardList from "./pages/createCards/DeckCardList";
import LibraryDashboard from "./pages/library/librarydashboard";


function App() {

  const [collapsed, setCollapsed] = useState(false);
  const [image, setImage] = useState(false);
  const [toggled, setToggled] = useState(false);

  const {ready , setReady} = useContext(wordContext)

    const location = useLocation();

  const handleCollapsedChange = () => {
    setCollapsed(!collapsed);
  };

  const handleImageChange = (checked) => {
    setImage(checked);
  };

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

   // Add styles for the layout container
   const layoutStyle = {
    display: 'flex',
    alignItems: 'stretch',
    height: '100vh' // This assumes that you want your app to fill the viewport height
  };

  // Add styles for the content area
  const contentStyle = {
    flex: '1',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start', // Change 'center' to 'flex-start'
    overflowY: 'auto' // In case your content needs to scroll
  };


  return (
    <div style={layoutStyle}>
        {location.pathname !== '/signin' && location.pathname !== '/signup'&& (
            <Sidebar
                image={image}
                collapsed={collapsed}
                toggled={toggled}
                handleToggleSidebar={handleToggleSidebar}
                handleCollapsedChange={handleCollapsedChange}
            />
        )}

        <div style={contentStyle}>


    <Switch>

        //login
        <Route path='/signin' component={SignInSide} />
        <Route path='/signup' component={SignUp} />

        // review tables
          <Route path='/reviewTable/:test_id'  component={Review_table} />
          <Route path='/reviewDrillTable/:drill_id'  component={Review_Drilltable} />

        <Route path="/ResultTable" component={ResultTable} />
          <Route path="/Drill_table" component={RecordDrill} />
          <Route path="/statistics" component={Statistics} />
          <Route path="/Take_test" component={Take_test} />
          <Route path="/TakeDrill" component={StartDrill} />
         <Route path="/WordResultTable" component={WordResultTable} />

        // cards
        <Route path="/card_dashboard" component={CardDashBoard} />
        <Route path="/create_card" component={CreateCard} />
       //<Route path="/deck_cardlist" component={DeckCardList} />
        <Route path="/deck/:deck_name" component={DeckCardList} />

        <Route path="/library_dashboard" component={LibraryDashboard} />
        <Route path='/home' component={Home} />
          <Route path='/' component={Home} />
    </Switch>
    </div>

    </div>
  );
}

export default App;
