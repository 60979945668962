import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, MenuItem, Select, InputLabel, FormControl, Grid, Snackbar } from '@mui/material';
import API from "../../services/axiosConfig";

function DataInputModal({ open, handleClose, deck, isNewDeck, newDeckName }) {
    const [data, setData] = useState([]);
    const [delimiter, setDelimiter] = useState('\\t');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarDuration, setSnackbarDuration] = useState(6000); // Default duration for Snackbar

    const handlePaste = (event) => {
        event.preventDefault();
        const pastedData = event.clipboardData.getData('Text');
        const rows = pastedData.split('\n');
        const newData = rows.map(row => {
            const [word, meaning] = row.split(delimiter === '\\t' ? '\t' : delimiter);
            return { word: word?.trim() || '', meaning: meaning?.trim() || '' };
        });
        setData(newData);
    };

    const handleSaveData = async () => {
        try {
            let selectedDeck = deck;

            if (isNewDeck && newDeckName.trim() !== '') {
                // Step 1: Create the new deck
                const newDeckData = { deck_name: newDeckName };
                const createDeckResponse = await API.post(`${process.env.REACT_APP_API_URL}:3006/add_deck`, newDeckData);

                if (createDeckResponse.status === 200) {
                    selectedDeck = newDeckName; // Set the new deck name to selectedDeck
                    setSnackbarMessage('New deck created successfully!');
                    setSnackbarOpen(true);
                } else {
                    setSnackbarMessage('Failed to create new deck');
                    setSnackbarOpen(true);
                    return; // Exit the function if deck creation fails
                }
            }

            // Step 2: Save the flashcards to the selected deck
            const response = await API.post(`${process.env.REACT_APP_API_URL}:3006/upload_spreadsheet`, {
                deckdata: selectedDeck,
                flashcards: data
            });

            if (response.status === 200) {
                setSnackbarMessage('Data inserted successfully!');
                setSnackbarOpen(true);
                setSnackbarDuration(6000); // Set duration for Snackbar display
                // Delay the modal closure slightly to ensure the Snackbar is visible
                setTimeout(handleClose, 1000);
            } else {
                setSnackbarMessage('Failed to upload data');
                setSnackbarOpen(true);
            }
        } catch (error) {
            console.error('Error:', error);
            setSnackbarMessage('Network error occurred');
            setSnackbarOpen(true);
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <>
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
                <DialogTitle>Paste Your Data</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Paste Data"
                        placeholder="Paste your data here"
                        multiline
                        rows={5}
                        fullWidth
                        variant="outlined"
                        onPaste={handlePaste}
                    />
                    <FormControl fullWidth style={{ marginTop: 20 }}>
                        <InputLabel>Delimiter</InputLabel>
                        <Select
                            value={delimiter}
                            onChange={(e) => setDelimiter(e.target.value)}
                        >
                            <MenuItem value="\\t">Tab</MenuItem>
                            <MenuItem value=",">Comma</MenuItem>
                            <MenuItem value=";">Semi-Colon</MenuItem>
                            <MenuItem value=" ">Space</MenuItem>
                            <MenuItem value="custom">Custom</MenuItem>
                        </Select>
                    </FormControl>
                    <Grid container spacing={2} style={{ marginTop: 20 }}>
                        {data.map((row, index) => (
                            <Grid item xs={6} key={index}>
                                <TextField
                                    label="Word"
                                    value={row.word}
                                    fullWidth
                                    variant="outlined"
                                    InputProps={{ readOnly: true }}
                                />
                                <TextField
                                    label="Meaning"
                                    value={row.meaning}
                                    fullWidth
                                    variant="outlined"
                                    InputProps={{ readOnly: true }}
                                    style={{ marginTop: 10 }}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                        Close
                    </Button>
                    <Button onClick={handleSaveData} color="primary">
                        Save Data
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={snackbarDuration}
                onClose={handleSnackbarClose}
                message={snackbarMessage}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            />
        </>
    );
}

export default DataInputModal;
