import React, { useState, useEffect } from 'react';
import './CreateCard.css';
import API from '../../services/axiosConfig';
import { FormControl, InputLabel, Select, MenuItem, Button, TextField } from '@mui/material';
import DataInputModal from './DataInputModal';

const CreateCard = () => {
    const [flashcards, setFlashcards] = useState([
        { question: '', answer: '' },
        { question: '', answer: '' },
        { question: '', answer: '' },
        { question: '', answer: '' },
        { question: '', answer: '' }
    ]);
    const [decks, setDecks] = useState([]);
    const [selectedDeck, setSelectedDeck] = useState('');
    const [uploadOption, setUploadOption] = useState('existing');
    const [inputMethod, setInputMethod] = useState('spreadsheet'); // 'spreadsheet' or 'custom'
    const [newDeckName, setNewDeckName] = useState('');
    const [customDeckOption, setCustomDeckOption] = useState('existing'); // 'existing' or 'new'

    // State for Modal
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        API.get(`${process.env.REACT_APP_API_URL}:3006/decks`)
            .then(response => {
                setDecks(response.data);
                if (response.data.length > 0) {
                    setSelectedDeck(response.data[0].deck_name);
                }
            })
            .catch(error => console.error("There was an error fetching the decks:", error));
    }, []);

    const handleUpload = () => {
        setIsModalOpen(true);
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
    };

    const handleQuestionChange = (index, event) => {
        const newFlashcards = flashcards.map((flashcard, i) => {
            if (i === index) {
                return { ...flashcard, question: event.target.value };
            }
            return flashcard;
        });
        setFlashcards(newFlashcards);
    };

    const handleAnswerChange = (index, event) => {
        const newFlashcards = flashcards.map((flashcard, i) => {
            if (i === index) {
                return { ...flashcard, answer: event.target.value };
            }
            return flashcard;
        });
        setFlashcards(newFlashcards);
    };

    const handleAddCard = () => {
        setFlashcards([...flashcards, { question: '', answer: '' }]);
    };

    const handleSave = () => {
        if (customDeckOption === 'new' && newDeckName.trim() !== '') {
            // Step 1: Create the new deck
            const newDeckData = { deck_name: newDeckName };

            API.post(`${process.env.REACT_APP_API_URL}:3006/add_deck`, newDeckData)
                .then(response => {
                    console.log('New deck created successfully:', response.data);

                    // Step 2: Save the flashcards to the newly created deck
                    const dataToSave = {
                        deck: newDeckName, // Use the newly created deck's name
                        flashcards: flashcards,
                    };

                    return API.post(`${process.env.REACT_APP_API_URL}:3006/add_cards`, dataToSave);
                })
                .then(response => {
                    console.log('Flashcards saved successfully:', response.data);
                    window.alert('New deck and flashcards saved successfully!');
                    window.location.reload(); // Refresh the page after saving
                })
                .catch(error => {
                    console.error('There was an error saving the new deck and flashcards:', error);
                    window.alert('Failed to save the new deck and flashcards. Please try again.');
                });
        } else if (customDeckOption === 'existing' && selectedDeck !== '') {
            // Save the flashcards to the existing selected deck
            const dataToSave = {
                deck: selectedDeck,
                flashcards: flashcards,
            };

            API.post(`${process.env.REACT_APP_API_URL}:3006/add_cards`, dataToSave)
                .then(response => {
                    console.log('Flashcards saved successfully:', response.data);
                    window.alert('Flashcards saved successfully!');
                    window.location.reload(); // Refresh the page after saving
                })
                .catch(error => {
                    console.error('There was an error saving the flashcards:', error);
                    window.alert('Failed to save the flashcards. Please try again.');
                });
        } else {
            window.alert('Please select a deck or create a new one before saving.');
        }
    };

    return (
        <div className="flashcard-app">
            <div className="flashcard-header">
                <h2>Create Flashcards</h2>
            </div>

            <div className="input-method">
                <FormControl fullWidth margin="normal">
                    <InputLabel id="input-method-label">Input Method</InputLabel>
                    <Select
                        labelId="input-method-label"
                        value={inputMethod}
                        onChange={(e) => setInputMethod(e.target.value)}
                        label="Input Method"
                    >
                        <MenuItem value="spreadsheet">Upload Spreadsheet</MenuItem>
                        <MenuItem value="custom">Custom Create Cards</MenuItem>
                    </Select>
                </FormControl>
            </div>

            {inputMethod === 'spreadsheet' && (
                <div className="upload-section">
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="upload-option-label">Upload Option</InputLabel>
                        <Select
                            labelId="upload-option-label"
                            value={uploadOption}
                            onChange={(e) => setUploadOption(e.target.value)}
                            label="Upload Option"
                        >
                            <MenuItem value="existing">Add to Existing Deck</MenuItem>
                            <MenuItem value="new">Create New Deck</MenuItem>
                        </Select>
                    </FormControl>

                    {uploadOption === 'existing' && (
                        <FormControl fullWidth margin="normal">
                            <InputLabel id="deck-select-label">Select Deck</InputLabel>
                            <Select
                                labelId="deck-select-label"
                                value={selectedDeck}
                                onChange={(e) => setSelectedDeck(e.target.value)}
                                label="Select Deck"
                            >
                                {decks.map((deck) => (
                                    <MenuItem key={deck.deck_name} value={deck.deck_name}>
                                        {deck.deck_name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}

                    {uploadOption === 'new' && (
                        <TextField
                            fullWidth
                            margin="normal"
                            label="New Deck Name"
                            value={newDeckName}
                            onChange={(e) => setNewDeckName(e.target.value)}
                        />
                    )}

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleUpload}
                        style={{ marginTop: '10px' }}
                    >
                        Upload
                    </Button>
                </div>
            )}

            {inputMethod === 'custom' && (
                <div>
                    <div className="custom-deck-option">
                        <FormControl fullWidth margin="normal">
                            <InputLabel id="custom-deck-option-label">Deck Option</InputLabel>
                            <Select
                                labelId="custom-deck-option-label"
                                value={customDeckOption}
                                onChange={(e) => setCustomDeckOption(e.target.value)}
                                label="Deck Option"
                            >
                                <MenuItem value="existing">Add to Existing Deck</MenuItem>
                                <MenuItem value="new">Create New Deck</MenuItem>
                            </Select>
                        </FormControl>
                    </div>

                    {customDeckOption === 'existing' && (
                        <div className="deck-selector">
                            <FormControl fullWidth margin="normal">
                                <InputLabel id="deck-select-label">Select Deck</InputLabel>
                                <Select
                                    labelId="deck-select-label"
                                    value={selectedDeck}
                                    onChange={(e) => setSelectedDeck(e.target.value)}
                                    label="Select Deck"
                                >
                                    {decks.map((deck) => (
                                        <MenuItem key={deck.deck_name} value={deck.deck_name}>
                                            {deck.deck_name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    )}

                    {customDeckOption === 'new' && (
                        <TextField
                            fullWidth
                            margin="normal"
                            label="New Deck Name"
                            value={newDeckName}
                            onChange={(e) => setNewDeckName(e.target.value)}
                        />
                    )}

                    {flashcards.map((flashcard, index) => (
                        <div key={index} className="flashcard">
                            <div className="flashcard-title">Flashcard {index + 1}</div>
                            <div className="flashcard-body">
                                <TextField
                                    fullWidth
                                    label="Question"
                                    className="input-field question"
                                    placeholder="Question?"
                                    value={flashcard.question}
                                    onChange={(e) => handleQuestionChange(index, e)}
                                    margin="normal"
                                />
                                <TextField
                                    fullWidth
                                    label="Answer"
                                    className="input-field answer"
                                    placeholder="Answer"
                                    value={flashcard.answer}
                                    onChange={(e) => handleAnswerChange(index, e)}
                                    margin="normal"
                                />
                            </div>
                        </div>
                    ))}
                    <div className="flashcard-controls">
                        <Button variant="outlined" onClick={handleAddCard} style={{ marginRight: '10px' }}>
                            Add a new card
                        </Button>
                        <Button variant="contained" color="primary" onClick={handleSave}>
                            Save
                        </Button>
                    </div>
                </div>
            )}

            {/* Modal Component */}
            {isModalOpen && (
                <DataInputModal
                    open={isModalOpen}
                    handleClose={handleModalClose}
                    deck={uploadOption === 'new' ? newDeckName : selectedDeck}
                    isNewDeck={uploadOption === 'new'}
                    newDeckName={newDeckName}
                />
            )}
        </div>
    );
};

export default CreateCard;
