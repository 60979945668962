import React, { useContext, useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { wordContext} from "../../contexts/wordContext";
import { UAParser } from 'ua-parser-js';
import { v4 as uuidv4 } from 'uuid';
import backgroundImage from '../../assets/images/valley.jpg';


const getDeviceId = () => {
    let deviceId = localStorage.getItem('device_id');
    if (!deviceId) {
        deviceId = uuidv4();
        localStorage.setItem('device_id', deviceId);
    }
    return deviceId;
};



// Copyright component
function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://mui.com/">
                Your Website
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

// Default theme
const defaultTheme = createTheme();

// SignInSide component
export default function SignInSide() {

    const history = useHistory();
    const { isLoggedIn, setIsLoggedIn } = useContext(wordContext);
    const [deviceType, setDeviceType] = useState('');

    const deviceId = getDeviceId();
    // Determine device type
    useEffect(() => {
        const parser = new UAParser();
        const result = parser.getResult();
        const type = result.device.type || 'desktop'; // default to 'desktop' if type is undefined
        setDeviceType(type);
    }, []);



    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const email = data.get('email');
        const password = data.get('password');

        axios.post(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_SERVER_PORT_NUM}/login/signin`,
            { email, password, deviceId, deviceType },
            { withCredentials: true }) // Include credentials
            .then((response) => {
                console.log("Full response: ", response); // To check the full structure of the response
                const { accessToken } = response.data;
                console.log("token: ", accessToken);

                if (accessToken) {
                    localStorage.setItem('accessToken', accessToken);
                    //setIsLoggedIn(true); // Assuming you want to update login state
                    localStorage.setItem('isLoggedIn' , true )
                    history.push('/home'); // Redirecting to home after login

                    // Check for the presence of the Set-Cookie header
                    const setCookieHeader = response.headers['set-cookie'];
                    if (setCookieHeader) {
                        console.log('Refresh token cookie was set');
                    } else {
                        console.error('No Set-Cookie header found for refresh token');
                    }
                } else {
                    console.error('Token not found in response');
                }
            })
            .catch((error) => {
                console.error('Error during sign in:', error);
                // Optionally set error messages to state here
            });
    };



    return (
        <ThemeProvider theme={defaultTheme}>
            <Grid container component="main" sx={{ height: '100vh' }}>
                <CssBaseline />
                <Grid
                    item
                    xs={12} // Adjust for smaller screens
                    sm={6} // Adjust for medium screens
                    md={7} // Adjust for larger screens
                    sx={{
                        minHeight: 500, // Set a minimum height

                        backgroundImage: `url(${backgroundImage})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: (t) =>
                            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        height: '100vh', // Full viewport height

                    }}
                />
                <Grid item xs={12} sm={6} md={5} component={Paper} elevation={6} square>
                    <Box
                        sx={{
                            my: 8,
                            mx: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Sign in
                        </Typography>
                        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                            />
                            <FormControlLabel
                                control={<Checkbox value="remember" color="primary" />}
                                label="Remember me"
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Sign In
                            </Button>
                            <Grid container>
                                <Grid item xs>
                                    <Link to="/forgot-password" variant="body2">
                                        Forgot password?
                                    </Link>
                                </Grid>
                                <Grid item>
                                    <Link to="/signup" variant="body2">
                                        {"Don't have an account? Sign Up"}
                                    </Link>
                                </Grid>
                            </Grid>
                            <Copyright sx={{ mt: 5 }} />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
}
