import React, { useEffect, useState, useContext } from 'react';
import './CardDashBoard.css';
import notebook_icon from '../../assets/images/notebook_icon_transparent.png';
import { useHistory } from 'react-router-dom';
import API from '../../services/axiosConfig';
import { wordContext } from "../../contexts/wordContext";
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, SpeedDial, SpeedDialIcon, SpeedDialAction, Snackbar } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import DeleteIcon from '@mui/icons-material/Delete';

const CreateCardDashBoard = () => {
    const [folders, setFolders] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newDeckName, setNewDeckName] = useState('');
    const [selectedFolder, setSelectedFolder] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const history = useHistory();
    const { isLoggedIn, setIsLoggedIn } = useContext(wordContext);

    useEffect(() => {
        API.get(`${process.env.REACT_APP_API_URL}:3006/get_decks`)
            .then(response => {
                const folderData = response.data.map(deck => ({
                    name: deck.deck_name,
                    icon: notebook_icon,
                    route: `deck/${deck.deck_name}`
                }));
                const extraFolderAddPlus = { name: 'add+', icon: notebook_icon, route: '/create_card' };

                folderData.push(extraFolderAddPlus);

                setFolders(folderData);
            })
            .catch(error => console.error("There was an error fetching the decks:", error));
    }, [isLoggedIn]);

    const handleAddClick = () => {
        setIsModalOpen(true);
    };

    const handleClose = () => {
        setIsModalOpen(false);
        setNewDeckName('');
    };

    const handleSave = () => {
        if (newDeckName.trim() !== '') {
            API.post(`${process.env.REACT_APP_API_URL}:3006/add_deck`, { deck_name: newDeckName })
                .then(response => {
                    console.log("New deck created:", response.data);
                    setFolders(prevFolders => [
                        ...prevFolders,
                        { name: newDeckName, icon: notebook_icon, route: `deck/${newDeckName}` }
                    ]);
                    handleClose();
                })
                .catch(error => console.error("There was an error creating the deck:", error));
        }
    };

    const deleteDeck = () => {
        if (selectedFolder) {
            API.delete(`${process.env.REACT_APP_API_URL}:3006/delete_deck/${selectedFolder}`)
                .then(response => {
                    console.log("Deck deleted:", response.data);
                    setFolders(folders.filter(folder => folder.name !== selectedFolder));
                    setSelectedFolder(null);
                })
                .catch(error => console.error("There was an error deleting the deck:", error));
        }
    };

    const shareDeck = () => {
        if (selectedFolder) {
            const requestData = {
                deck_name: selectedFolder,
                // Add any other necessary data for sharing the deck
            };

            API.post(`${process.env.REACT_APP_API_URL}:3006/sharing_decks`, requestData)
                .then(response => {
                    console.log('Share deck successful:', response.data);
                    setSnackbarMessage('Deck shared successfully!');
                    setSnackbarOpen(true);
                })
                .catch(error => {
                    console.error('Error sharing the deck:', error);

                    // Extract the error message from the response
                    const errorMessage = error.response?.data?.error || 'An error occurred while sharing the deck.';
                    setSnackbarMessage(`Error: ${errorMessage}`);
                    setSnackbarOpen(true);
                });
        }
    };


    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const FolderBox = ({ name, icon, route }) => {
        const handleClick = () => {
            if (selectedFolder === name) {
                history.push(route);
            } else {
                setSelectedFolder(name);
            }
        };

        return (
            <div
                className={`folder-box ${selectedFolder === name ? 'selected' : ''}`}
                onClick={handleClick}
            >
                <img src={icon} alt={name} />
                <p>{name}</p>
            </div>
        );
    };

    return (
        <div className="cardDashBoard">
            <div className="dash-header">Hello</div>
            <div className="dash-body">
                {folders.map((folder, index) => (
                    <FolderBox key={index} name={folder.name} icon={folder.icon} route={folder.route} />
                ))}
            </div>
            <Dialog
                open={isModalOpen}
                onClose={handleClose}
                fullWidth
                sx={{
                    '& .MuiDialog-paper': {
                        minWidth: '20px',
                        minHeight: '200px'
                    }
                }} >
                <DialogTitle>Create a New Deck</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Deck Name"
                        type="text"
                        fullWidth
                        value={newDeckName}
                        onChange={(e) => setNewDeckName(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSave} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Render SpeedDial only when a folder is selected */}
            {selectedFolder && (
                <SpeedDial
                    ariaLabel="SpeedDial actions"
                    sx={{ position: 'fixed', bottom: 16, right: 16 }}
                    icon={<SpeedDialIcon />}
                >
                    <SpeedDialAction
                        icon={<ShareIcon />}
                        tooltipTitle="Share Deck"
                        onClick={shareDeck}
                    />
                    <SpeedDialAction
                        icon={<DeleteIcon />}
                        tooltipTitle="Delete Deck"
                        onClick={deleteDeck}
                    />
                </SpeedDial>
            )}

            {/* Snackbar for displaying messages */}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                message={snackbarMessage}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            />
        </div>
    );
};

export default CreateCardDashBoard;