import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './LanguageSwitcher.module.scss'; // Assuming SCSS file is in the same folder

const LanguageSwitcher = () => {
    const { i18n } = useTranslation();

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    return (
        <div className={styles.languageSwitcher}>
            <button
                onClick={() => changeLanguage('en')}
                className={`${styles.languageButton} ${styles.english}`}
            >
                English
            </button>
            <button
                onClick={() => changeLanguage('ko')}
                className={`${styles.languageButton} ${styles.korean}`}
            >
                한국어
            </button>
        </div>
    );
};

export default LanguageSwitcher;
