
import React, {useState, useEffect, useContext} from 'react';
import {wordContext} from "../contexts/wordContext";
import API from "../services/axiosConfig";

const GetCardDeck = () => {
    const [decks , setDecks] = useState([]) ;
    const { isLoggedIn, setIsLoggedIn } = useContext(wordContext);

    useEffect(() => {
        fetchDecks();
    }, [isLoggedIn]) ;

    const fetchDecks = async () => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await API.get(`${apiUrl}:3006/get_decks`);
            const data = await response.data ;
            setDecks(data) ;
            console.log("deckdata: " , data)
            } catch (error) {
                console.error('Error fetching tables:', error);

            }
        };
        return decks ; 


}
export default GetCardDeck ; 
