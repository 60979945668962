import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { WordContextProvider } from './contexts/wordContext';
import { I18nextProvider } from 'react-i18next';
import i18n from './services/i18n'; // Make sure the path to i18n.js is correct

const rootElement = document.getElementById('root');
ReactDOM.render(
    <StrictMode>
        <I18nextProvider i18n={i18n}>
            <WordContextProvider>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </WordContextProvider>
        </I18nextProvider>
    </StrictMode>,
    rootElement
);
