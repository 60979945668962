import React, { useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, Table, TableBody, TableCell, TableHead, TableRow, Snackbar } from '@mui/material';
import API from '../../services/axiosConfig'; // Adjust the import path as necessary

const DashDialog = ({
                        isOpen,
                        handleClose,
                        newDeckName,
                        setNewDeckName,
                        selectedDeck,
                        isAddDeckModalOpen,
                    }) => {
    const [deckDetails, setDeckDetails] = useState(null);
    const [deckRows, setDeckRows] = useState([]);
    const [alertOpen, setAlertOpen] = useState(false); // State for Snackbar visibility
    const [alertMessage, setAlertMessage] = useState(''); // State for Snackbar message
    const [alertSeverity, setAlertSeverity] = useState('success'); // State for Snackbar type (success or error)

    useEffect(() => {
        if (isOpen && selectedDeck) {
            // Fetch deck details from the API
            API.get(`${process.env.REACT_APP_API_URL}:3006/library/dialog_data/${selectedDeck}`)
                .then(response => {
                    console.log("API Response:", response.data); // Log the full API response

                    if (response.data && response.data.length > 0) {
                        const deckData = response.data[0];
                        setDeckDetails({
                            deck_name: deckData.deck_name,
                            total_rows: deckData.total_rows,
                            creator_id: deckData.creator_id
                        });

                        // Set deckRows with the response data directly
                        setDeckRows(response.data.slice(0, 10)); // Limit to 10 rows
                    } else {
                        console.error("No data found in response");
                    }
                })
                .catch(error => console.error("There was an error fetching the deck details:", error));
        }
    }, [isOpen, selectedDeck]);

    const handleDownload = () => {
        if (deckDetails) {
            const requestData = {
                deck_name: deckDetails.deck_name,
                creator_id: deckDetails.creator_id,
            };

            API.post(`${process.env.REACT_APP_API_URL}:3006/download_deck`, requestData)
                .then(response => {
                    // Handle the response here, e.g., trigger file download or show a success message
                    console.log('Download successful:', response.data);
                    setAlertMessage('Download successful!');
                    setAlertSeverity('success');
                    setAlertOpen(true); // Open the Snackbar
                })
                .catch(error => {
                    console.error('Error downloading the deck:', error);
                    setAlertMessage('Download failed. Please try again.');
                    setAlertSeverity('error');
                    setAlertOpen(true); // Open the Snackbar
                });
        }
    };

    const handleCloseAlert = () => {
        setAlertOpen(false); // Close the Snackbar
    };

    return (
        <>
            <Dialog
                open={isAddDeckModalOpen}
                onClose={handleClose}
                fullWidth
                sx={{
                    '& .MuiDialog-paper': {
                        minWidth: '20px',
                        minHeight: '200px'
                    }
                }}
            >
                <DialogTitle>Create a New Deck</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Deck Name"
                        type="text"
                        fullWidth
                        value={newDeckName}
                        onChange={(e) => setNewDeckName(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={isOpen && selectedDeck !== null}
                onClose={handleClose}
                fullWidth
                maxWidth="lg"
                sx={{
                    '& .MuiDialog-paper': {
                        minWidth: '800px',
                        minHeight: '600px'
                    }
                }}
            >
                <DialogTitle>{deckDetails ? deckDetails.deck_name : 'Loading...'}</DialogTitle>
                <DialogContent>
                    {deckDetails ? (
                        <>
                            <p>Deck Name: {deckDetails.deck_name}</p>
                            <p>Number of Rows: {deckDetails.total_rows}</p>
                            <p>Creator ID: {deckDetails.creator_id}</p>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Word Front</TableCell>
                                        <TableCell>Word Back</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {deckRows.length > 0 ? (
                                        deckRows.map((row, rowIndex) => (
                                            <TableRow key={rowIndex}>
                                                {console.log('Row:', row)} {/* Log each row */}
                                                <TableCell>{row.word_front}</TableCell>
                                                <TableCell>{row.word_back}</TableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={2}>No data available</TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </>
                    ) : (
                        <p>Loading deck details...</p>
                    )}
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleDownload} color="primary" variant="contained">
                        Download
                    </Button>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={alertOpen}
                autoHideDuration={6000}
                onClose={handleCloseAlert}
                message={alertMessage}
                action={
                    <Button color="inherit" size="small" onClick={handleCloseAlert}>
                        Close
                    </Button>
                }
            />
        </>
    );
};

export default DashDialog;
