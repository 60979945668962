import React, { useContext, useEffect } from 'react'; // Import useContext and useEffect
import { Link, NavLink } from 'react-router-dom';
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent
} from 'react-pro-sidebar';
import {
  FaUser,
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaTachometerAlt,
  FaGem,
  FaList,
  FaRegLaughWink,
  ImTable,
  HiTableCells,
  FaSearch,
  FaHeart,
  FaTable,
} from 'react-icons/fa';
import { ImStatsBars } from 'react-icons/im';
import { MdAddToPhotos } from "react-icons/md";
import { DiCodeigniter } from "react-icons/di";
import "./Sidebar.scss"
import RecordDrill from "../../pages/RecordDrill";
import { FaSignInAlt, FaUserPlus } from 'react-icons/fa'; // Importing icons
import { FaSignOutAlt } from 'react-icons/fa'; // Importing logout icon
import { wordContext } from "../../contexts/wordContext";
import { useTranslation } from 'react-i18next';
import { IoLibrary } from "react-icons/io5";


// Import the LanguageSwitcher component
import LanguageSwitcher from './LanguageSwitcher'; // Ensure the correct path to your component

const Sidebar = ({
                   image,
                   collapsed,
                   toggled,
                   handleToggleSidebar,
                   handleCollapsedChange
                 }) => {
  const { isLoggedIn, setIsLoggedIn } = useContext(wordContext);

  useEffect(() => {
    const storedIsLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
    setIsLoggedIn(storedIsLoggedIn);
  }, [setIsLoggedIn]);

  const handleLogout = () => {
    localStorage.removeItem('accessToken');
    localStorage.setItem('isLoggedIn', 'false');
    setIsLoggedIn(false);
  };

  const { t } = useTranslation();

  const style = {
    container: {
      display: 'flex',
      justifyContent: 'center',
      gap: '20px',
    },
    link: {
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
    },
    icon: {
      marginRight: '8px',
    },
    span: {
      fontSize: "15",
      fontWeight: 'bold',
      color: 'rgba(51, 0, 0, 1)',
    }
  };

  return (
      <ProSidebar
          collapsed={collapsed}
          toggled={toggled}
          onToggle={handleToggleSidebar}
          breakPoint="md"
      >
        <SidebarHeader>
          <Menu iconShape="circle">
            {collapsed ? (
                <MenuItem
                    icon={<FaAngleDoubleRight />}
                    onClick={handleCollapsedChange}
                ></MenuItem>
            ) : (
                <MenuItem
                    suffix={<FaAngleDoubleLeft />}
                    onClick={handleCollapsedChange}
                >
                  <div
                      style={{
                        padding: '9px',
                        textTransform: 'uppercase',
                        fontWeight: 'bold',
                        fontSize: 15,
                        letterSpacing: '1px'
                      }}
                  >
                    The Test Grinder
                  </div>
                </MenuItem>
            )}
          </Menu>
        </SidebarHeader>
        <SidebarContent>
          <Menu iconShape="circle">
            <MenuItem
                icon={<FaTachometerAlt />}
                suffix={<span className="badge red">NEW</span>}
            >
              {t('home')}
              <NavLink to="/home" />
            </MenuItem>
            <MenuItem icon={<MdAddToPhotos />}>
              {t('create_cards')}  <Link to="/card_dashboard" />
            </MenuItem>
            <MenuItem icon={<FaGem />}>
              {t('take_test')} <Link to="/Take_test" />
            </MenuItem>
            <MenuItem icon={<DiCodeigniter />}>
              {t('start_drill')} <Link to="/TakeDrill" />
            </MenuItem>

            <SubMenu
                title={t('records')}
                icon={<FaTable />}
            >
              <MenuItem>
                {t('test_records')} <Link to="/ResultTable" />
              </MenuItem>
              <MenuItem>
                {t('drill_records')} <Link to="/Drill_table" />
              </MenuItem>
              <MenuItem>
                {t('word_result_records')} <Link to="/WordResultTable" />
              </MenuItem>
            </SubMenu>

            <MenuItem icon={<ImStatsBars />}>
              {t('statistics')} <Link to="/statistics" />
            </MenuItem>
            <MenuItem icon={<IoLibrary />}>
              {t('library')} <Link to="/library_dashboard" />
            </MenuItem>

          </Menu>
        </SidebarContent>
        <SidebarFooter style={{ textAlign: 'center' }}>
          <div className="sidebar-btn-wrapper" style={{ padding: '20px' }}>

            <LanguageSwitcher /> {/* Added Language Switcher above the auth buttons */}

            {!isLoggedIn ? (
                <div style={style.container}>
                  <Link to="/signin" className="sidebar-btn" style={style.link}>
                    <FaSignInAlt style={style.icon} />
                    <span style={style.span}>{t('sign_in')}</span>
                  </Link>
                  <Link to="/signup" className="sidebar-btn" style={style.link}>
                    <FaUserPlus style={style.icon} />
                    <span style={style.span}>{t('sign_up')}</span>
                  </Link>
                </div>
            ) : (
                <div style={style.container}>
                  <Link className="sidebar-btn" style={{ cursor: 'pointer' }} to="/profile">
                    <FaUser />
                    <span>{t('my_account')}</span>
                  </Link>
                  <Link className="sidebar-btn" onClick={handleLogout} to="/home">
                    <FaSignOutAlt />
                    <span>{t('logout')}</span>
                  </Link>
                </div>
            )}
          </div>
        </SidebarFooter>
      </ProSidebar>
  );
};

export default Sidebar;
